.careersContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: rgba(11, 37, 65, 0.9);
  padding-top: 80px;
  padding-bottom: 80px;
  align-items: center;
  justify-content: center;
  margin: 0px;
  flex: 1;
}

.careersTitle {
  font-size: 2rem;
  font-weight: 600;
  background-color: rgba(206, 87, 47, 1);
  color: white;
  margin-bottom: 60px;
  border-radius: 2rem;
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
}

.careersText {
  max-width: 800px;
  text-align: justify;
  font-size: 1.2rem;
  line-height: 2.2rem;
  color: white;
  padding-left: 80px;
  padding-right: 80px;
}

.careersContactButton {
  margin-top: 40px;
  font-size: 1.3rem;
  cursor: pointer;
}

.careersContactButton > a {
  text-decoration: none;
  color: white;
}

.careersContactButton > a:hover {
  color: lavender;
}

@media screen and (max-width: 600px) {
  .careersText {
    font-size: 1rem;
    line-height: 1.4rem;
    text-align: left;
    padding-left: 40px;
    padding-right: 40px;
  }
}

.careersJobs {
  display: flex;
  flex-direction: column;
  margin-left: 60px;
  margin-right: 60px;;
  max-width: 1000px;
  align-items: left;
  justify-content: center;
}