.contactContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: rgba(11, 37, 65, 0.9);
  padding-top: 80px;
  padding-bottom: 80px;
  align-items: center;
  justify-content: center;
  margin: 0px;
  flex: 1;
}

.contactTitle {
  font-size: 2rem;
  font-weight: 600;
  color: white;
  margin-bottom: 60px;
  margin-left: 80px;
  margin-right: 80px;
  border-radius: 2rem;
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
  background-color: rgba(206, 87, 47, 1);
}

.contactText {
  text-align: left;
  min-width: 400px;
}

.contactTextContent {
  display: flex;
  flex-direction: row;
  width: 1000px;
  padding: 80px;
  background-color: rgba(255,255,255, 0.9);
  border-radius: 2rem;
}

.contactTextTitle {
  font-size: 1.6rem;
  margin-top: 30px;
  margin-bottom: 10px;
}

.contactTextTitle:first-of-type {
  margin-top: 0px;
}

.contactTextContentText {
  font-size: 1.2rem;
  line-height: 1.6rem;
}

.contactContactButton {
  margin-top: 40px;
  font-size: 1.3rem;
  cursor: pointer;
}

.contactContactButton > a {
  text-decoration: none;
  color: black;
}

.contactContactButton > a:hover {
  color: slategrey;
}

@media screen and (max-width: 1200px) {
  .contactTextContent {
    width: 60%;
    max-width: 800px;
    flex-direction: column;
    align-items: center;
  }

  .contactText {
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 100px;
    min-width: 0px;
  }

  .leafletContainer {
    width: 400px;
  }
}

@media screen and (max-width: 600px) {

  .contactTextContent {
    max-width: 300px;
    /* background-color: rgba(256, 256, 256, 0); */
  }

  .leafletContainer {
    height: 300px;
    width: 300px;
  }

  .contactTextTitle {
    font-size: 1.1rem;
  }

  .contactTextContentText {
    font-size: 0.9rem;
  }
}