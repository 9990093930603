.careerPosition {
  margin-bottom: 4px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.8) 50%);
}

.careerTitle {
  cursor: pointer;
  padding: 10px 15px;
  background-color: #f7f7f7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ddd;
}

.careerContent {
  border-top: none;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.careerFeature {
  text-align: start;
  padding-left: 30px;
  padding-right: 30px;
}

.careerHeading {
  padding-top: 20px;
  font-weight: 600;
}

.careerFeature {
  padding-bottom: 20px;
}

.noStyle {
  text-decoration: none;
  /* Remove underline */
  color: inherit;
  /* Use the color of its parent element */
}