.footerContent {
  /* min-height: 220px; */
  background-color: rgba(256, 256, 256, 1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
  padding-bottom: 40px;
}

.footerSection {
  margin-top: 20px;
  text-align: left;
  min-width: 160px;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 40px;
  margin-right: 40px;
}

.footerSection:first-of-type {
  margin-left: 40px;
  max-width: 150px;
}

.footerSection:last-of-type {
  margin-right: 40px;
}

.footerSectionTitle {
  font-size: 1.1rem;
  font-weight: 600;
  color: rgba(11, 37, 65, 1);
}

.footerSectionContent {
  margin-top: 14px;
  font-size: 0.9rem;
  line-height: 1.5rem;
}

.footerSectionContent > div {
  margin-bottom: 4px;
}

.footerSectionContent > * > a {
  text-decoration: none;
  color: black;
}


.visitUsContent {
  font-size: 0.9rem;
}

sup {
  font-size: 0.6rem;
}