.cls19-1,
.cls19-2 {
  fill: #f2f2f2;
}

.cls19-1,
.cls19-2,
.cls19-3,
.cls19-4 {
  fill-rule: evenodd;
}

.cls19-1,
.cls19-3,
.cls19-5,
.cls19-6,
.cls19-7,
.cls19-4,
.cls19-8,
.cls19-9 {
  stroke-width: 0px;
}

.cls19-2,
.cls19-10,
.cls19-11,
.cls19-12,
.cls19-13,
.cls19-14,
.cls19-15,
.cls19-16,
.cls19-17,
.cls19-18,
.cls19-19,
.cls19-20,
.cls19-21,
.cls19-22,
.cls19-23,
.cls19-24 {
  stroke-width: .5px;
}

.cls19-2,
.cls19-11,
.cls19-13,
.cls19-14,
.cls19-15,
.cls19-16,
.cls19-17,
.cls19-19,
.cls19-20,
.cls19-21,
.cls19-22,
.cls19-23,
.cls19-24 {
  stroke-miterlimit: 8;
}

.cls19-2,
.cls19-13,
.cls19-14,
.cls19-15,
.cls19-19,
.cls19-20,
.cls19-21,
.cls19-22,
.cls19-23,
.cls19-24 {
  stroke: #a3a3a3;
}

.cls19-2,
.cls19-20 {
  stroke-dasharray: 0 0 1.5 1.5;
}

.cls19-10 {
  stroke-dasharray: 0 0 0 0 2 0 0 1.99;
}

.cls19-10,
.cls19-25,
.cls19-12,
.cls19-26,
.cls19-18 {
  stroke-miterlimit: 10;
}

.cls19-10,
.cls19-11,
.cls19-13,
.cls19-14,
.cls19-17,
.cls19-26,
.cls19-19,
.cls19-20,
.cls19-21,
.cls19-22,
.cls19-23 {
  fill: none;
}

.cls19-10,
.cls19-12,
.cls19-16,
.cls19-18 {
  stroke: #000;
}

.cls19-25 {
  fill: url(#radial-gradient-2);
  stroke: #8898ae;
}

.cls19-3 {
  fill: #b385bb;
}

.cls19-27 {
  letter-spacing: 0em;
}

.cls19-11 {
  stroke: #7b7b7b;
}

.cls19-5 {
  fill: url(#radial-gradient);
}

.cls19-12 {
  fill: #72cdf4;
}

.cls19-14 {
  stroke-dasharray: 0 0 1.4 1.4;
}

.cls19-6 {
  fill: #000;
}

.cls19-7 {
  fill: #2f4e76;
}

.cls19-4 {
  fill: #709ad1;
}

.cls19-15 {
  fill: #d8d8d8;
}

.cls19-15,
.cls19-23,
.cls19-24 {
  stroke-dasharray: 0 0 1.5 1.5;
}

.cls19-16,
.cls19-8 {
  fill: #fff;
}

.cls19-28 {
  letter-spacing: 0em;
}

.cls19-17 {
  stroke: #b385bb;
}

.cls19-26 {
  stroke: #7296ce;
}

.cls19-18 {
  fill: #104c93;
}

.cls19-19 {
  stroke-dasharray: 0 0 1.5 1.5;
}

.cls19-21 {
  stroke-dasharray: 0 0 1.58 1.58;
}

.cls19-22 {
  stroke-dasharray: 0 0 1.2 1.2;
}

.cls19-24 {
  fill: #f2f2f3;
}

.cls19-9 {
  fill: #b96228;
}

.cls19-29 {
  fill: #231f20;
  font-family: Avenir-Light, Avenir;
  font-size: 7px;
  font-weight: 300;
}