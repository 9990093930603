.clsX-1,
.clsX-2,
.clsX-3,
.clsX-4 {
  fill-rule: evenodd;
}

.clsX-1,
.clsX-3,
.clsX-5 {
  fill: #f2f2f2;
}

.clsX-1,
.clsX-6,
.clsX-7,
.clsX-8,
.clsX-9,
.clsX-10,
.clsX-11,
.clsX-12,
.clsX-13,
.clsX-14,
.clsX-15,
.clsX-16,
.clsX-17,
.clsX-18,
.clsX-19,
.clsX-5,
.clsX-20 {
  stroke-width: .5px;
}

.clsX-1,
.clsX-7,
.clsX-9,
.clsX-10,
.clsX-11,
.clsX-12,
.clsX-13,
.clsX-15,
.clsX-16,
.clsX-17,
.clsX-18,
.clsX-19,
.clsX-5,
.clsX-20 {
  stroke-miterlimit: 8;
}

.clsX-1,
.clsX-9,
.clsX-10,
.clsX-11,
.clsX-15,
.clsX-16,
.clsX-17,
.clsX-18,
.clsX-19,
.clsX-5,
.clsX-20 {
  stroke: #a3a3a3;
}

.clsX-1,
.clsX-11,
.clsX-19,
.clsX-5,
.clsX-20 {
  stroke-dasharray: 0 0 1.5 1.5;
}

.clsX-2 {
  fill: #66bd4f;
}

.clsX-2,
.clsX-3,
.clsX-4,
.clsX-21,
.clsX-22,
.clsX-23,
.clsX-24,
.clsX-25 {
  stroke-width: 0px;
}

.clsX-6 {
  stroke-dasharray: 0 0 0 0 2 0 0 1.99;
}

.clsX-6,
.clsX-26,
.clsX-8,
.clsX-27,
.clsX-14 {
  stroke-miterlimit: 10;
}

.clsX-6,
.clsX-7,
.clsX-9,
.clsX-10,
.clsX-13,
.clsX-27,
.clsX-15,
.clsX-16,
.clsX-17,
.clsX-18,
.clsX-19 {
  fill: none;
}

.clsX-6,
.clsX-8,
.clsX-12,
.clsX-14 {
  stroke: #000;
}

.clsX-26 {
  fill: url(#radial-gradient-2);
  stroke: #8898ae;
}

.clsX-4 {
  fill: #b385bb;
}

.clsX-7 {
  stroke: #7b7b7b;
}

.clsX-21 {
  fill: url(#radial-gradient);
}

.clsX-8 {
  fill: #72cdf4;
}

.clsX-10 {
  stroke-dasharray: 0 0 1.4 1.4;
}

.clsX-22 {
  fill: #000;
}

.clsX-23 {
  fill: #2f4e76;
}

.clsX-11 {
  fill: #d8d8d8;
}

.clsX-12,
.clsX-24 {
  fill: #fff;
}

.clsX-13 {
  stroke: #b385bb;
}

.clsX-27 {
  stroke: #7296ce;
}

.clsX-14 {
  fill: #104c93;
}

.clsX-15 {
  stroke-dasharray: 0 0 1.5 1.5;
}

.clsX-16 {
  stroke-dasharray: 0 0 1.5 1.5;
}

.clsX-17 {
  stroke-dasharray: 0 0 1.58 1.58;
}

.clsX-18 {
  stroke-dasharray: 0 0 1.2 1.2;
}

.clsX-20 {
  fill: #f2f2f3;
}

.clsX-25 {
  fill: #b96228;
}

.clsX-28 {
  fill: #231f20;
  font-family: Avenir-Light, Avenir;
  font-size: 7px;
  font-weight: 300;
}