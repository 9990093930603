.personBox {
  cursor: pointer;
  display: block;
  max-width: 180px;
  padding-bottom: 20px;
  outline: none;
}

.personImage {
  width: 180px;
  /* clip-path: circle(50% at 50% 50%); */
  /* -webkit-clip-path: circle(50% at 50% 50%); */
  /* transition: filter 0.1s ease-in-out; */
  -webkit-filter: grayscale(100%);
  /* Ch 23+, Saf 6.0+, BB 10.0+ */
  filter: grayscale(100%);
  /* FF 35+ */
  height: 180px;
  transition: height, width 0.2s;
  -webkit-transition: height, width 0.2s;
}

.personImage:hover {
  /* transition: filter 0.1s ease-in-out; */
  -webkit-filter: grayscale(0%);
  /* Ch 23+, Saf 6.0+, BB 10.0+ */
  filter: grayscale(0%);
  /* FF 35+ */
}

.personLabel {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 60px;
  width: 100%;
  top: 10px;
  margin-bottom: 0px;
  text-align: center;
  font-size: 1.2rem;
  -webkit-transition: top 0.3, font-size 0.3s;
  transition: top 0.3s, font-size 0.3s;
}

.personBox {
  margin-bottom: 40px;
}

.personBox:hover > .personLabel {
  top: 2px;
  color: rgba(206, 87, 47, 1);
}

.personPosition {
  font-size: 0.9rem;
  max-width: 140px;
  align-self: center;
  justify-self: center;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.greyScale {
  /* transition: filter 0.1s ease-in-out; */
  -webkit-filter: grayscale(0%);
  /* Ch 23+, Saf 6.0+, BB 10.0+ */
  filter: grayscale(0%);
  /* FF 35+ */
}

/* Modal */

.modalOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  /* Semi-transparent black */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* High z-index to ensure the modal is on top of other elements */
}

.modal {
  width: 80%;
  /* Use a percentage to make the modal responsive */
  max-width: 500px;
  /* But also provide a max-width */
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  /* Add a small shadow for a lift effect */
}

.personBoxModal {
  overflow-y: auto;
  box-sizing: border-box;
  max-height: 80vh;
}

.personImageModal {
  margin-top: 40px;
  max-height: 180px;
}

.personLabelModal {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    top: 20px;
    margin-bottom: 0px;
    text-align: center;
    font-size: 1.2rem;
}

.personPositionModal {
  margin-top: 6px;
  font-size: 1.1rem;
}

.personDescription {
  font-size: 1.0rem;
  line-height: 1.6rem;;
  text-align: justify;
  padding: 40px;
}

.personClose {
  margin-bottom: 40px;
  cursor: pointer;
}

.personDescriptionParagraph {
  padding-bottom: 10px;
}