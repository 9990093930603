.aboutUsContent {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 100%;
  width: 100%;
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: rgba(11, 37, 65, 0.9);
}

.historySection {
  max-width: 580px;
  /* margin-left: 80px; */
  /* background-color: rgba(256, 256, 256, 0.2); */
  transition: max-width 0.5s ease;
  align-self: center;
  flex-direction: column;
  display: flex;
  margin-bottom: 80px;
}

.historySectionTitle {
  /* text-transform: uppercase; */
  text-align: left;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 60px;
  background-color: rgba(206, 87, 47, 1);
  color: white;
  border-radius: 2rem;
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
}

.historySectionText {
  /* background: linear-gradient(to bottom, rgba(1,53,99,0.9) 0%, rgba(1,53,99,0.8) 30%, rgba(1,53,99,0.6) 100%); */
  font-size: 1.1rem;
  text-align: justify;
  line-height: 2rem;
  padding: 20px;
  max-width: 800px;
  color: floralwhite;
}

.peopleSection {
  display: flex;
  flex-direction: column;
  /* max-width: calc(5 * 180px + 4 * 20px); */
  padding-left: 120px;
  padding-right: 120px;
  padding-bottom: 80px;
  padding-top: 120px;
  justify-content: flex-start;
  align-items: center;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.95) 100%);
}

.peopleList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.peopleTitle {
  /* text-transform: uppercase; */
  margin-bottom: 60px;
  font-size: 2rem;
  font-weight: 600;
  align-self: flex-start;
  background-color: rgba(11, 37, 65, 1);
  color: white;
  border-radius: 2rem;
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
}

.board.peopleTitle {
  margin-top: 80px;
}

@media screen and (min-width: 1900px) {
  .historySection {
    max-width: 680px;
  }
}

@media screen and (min-width: 2000px) {
  .historySection {
    max-width: 780px;
  }
}

@media screen and (min-width: 2100px) {
  .historySection {
    max-width: 880px;
  }
}

@media screen and (min-width: 2200px) {
  .historySection {
    max-width: 980px;
  }
}

@media screen and (min-width: 2300px) {
  .historySection {
    max-width: 1000px;
  }
}

@media screen and (max-width: 1535px) {
  .aboutUsContent {
    flex-direction: column;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .peopleSection {
    margin-left: 0px;
    margin-bottom: 0px;
  }

  .historySection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 100%;
    padding: 20px;
    margin-top: 60px;
    margin-left: 0px;
  }

  .peopleList {
    justify-content: center;
  }
}

@media screen and (max-width: 800px) {
  .peopleTitle {
    align-self: center;
  }
}