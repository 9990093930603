html, body {
  text-decoration: none;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -mos-osx-font-smoothing: grayscale;
  overflow: auto;
  margin: 0px;
}

.App {
  text-align: center;
  color: #2c3e50;
  /* position: absolute; */
  /* width: 100vw; */
  top: 0px;
  left: 0px;
  background: url('./../assets/img/background.jpg');
  background-attachment: fixed;
  background-size: cover;
  -webkit-background-size: cover;
  background-repeat: no-repeat;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* <-- for smooth scroll */
  /* transition-duration: 500ms; */
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}