.cls7-1,
.cls7-2,
.cls7-3,
.cls7-4,
.cls7-5,
.cls7-6,
.cls7-7,
.cls7-8 {
  stroke-width: 0px;
}

.cls7-1,
.cls7-2,
.cls7-4 {
  fill-rule: evenodd;
}

.cls7-1,
.cls7-9 {
  fill: #f2f2f2;
}

.cls7-10 {
  stroke-dasharray: 0 0 0 0 2 0 0 1.99;
}

.cls7-10,
.cls7-11,
.cls7-12,
.cls7-13,
.cls7-14 {
  stroke-miterlimit: 10;
}

.cls7-10,
.cls7-15,
.cls7-12,
.cls7-16,
.cls7-17,
.cls7-18,
.cls7-19,
.cls7-20,
.cls7-14,
.cls7-21,
.cls7-22,
.cls7-23,
.cls7-24,
.cls7-25,
.cls7-9,
.cls7-26 {
  stroke-width: .5px;
}

.cls7-10,
.cls7-15,
.cls7-16,
.cls7-17,
.cls7-20,
.cls7-13,
.cls7-21,
.cls7-22,
.cls7-23,
.cls7-24,
.cls7-25 {
  fill: none;
}

.cls7-10,
.cls7-12,
.cls7-19,
.cls7-14 {
  stroke: #000;
}

.cls7-11 {
  fill: url(#radial-gradient-2);
  stroke: #8898ae;
}

.cls7-2 {
  fill: #b385bb;
}

.cls7-27 {
  letter-spacing: 0em;
}

.cls7-15 {
  stroke: #7b7b7b;
}

.cls7-15,
.cls7-16,
.cls7-17,
.cls7-18,
.cls7-19,
.cls7-20,
.cls7-21,
.cls7-22,
.cls7-23,
.cls7-24,
.cls7-25,
.cls7-9,
.cls7-26 {
  stroke-miterlimit: 8;
}

.cls7-3 {
  fill: url(#radial-gradient);
}

.cls7-12 {
  fill: #72cdf4;
}

.cls7-16,
.cls7-17,
.cls7-18,
.cls7-21,
.cls7-22,
.cls7-23,
.cls7-24,
.cls7-25,
.cls7-9,
.cls7-26 {
  stroke: #a3a3a3;
}

.cls7-4 {
  fill: #de8343;
}

.cls7-17 {
  stroke-dasharray: 0 0 1.4 1.4;
}

.cls7-5 {
  fill: #000;
}

.cls7-6 {
  fill: #2f4e76;
}

.cls7-18 {
  fill: #d8d8d8;
}

.cls7-18,
.cls7-25,
.cls7-9,
.cls7-26 {
  stroke-dasharray: 0 0 1.5 1.5;
}

.cls7-19,
.cls7-7 {
  fill: #fff;
}

.cls7-28 {
  letter-spacing: 0em;
}

.cls7-20 {
  stroke: #b385bb;
}

.cls7-13 {
  stroke: #7296ce;
}

.cls7-14 {
  fill: #104c93;
}

.cls7-21 {
  stroke-dasharray: 0 0 1.5 1.5;
}

.cls7-22 {
  stroke-dasharray: 0 0 1.5 1.5;
}

.cls7-23 {
  stroke-dasharray: 0 0 1.58 1.58;
}

.cls7-24 {
  stroke-dasharray: 0 0 1.2 1.2;
}

.cls7-26 {
  fill: #f2f2f3;
}

.cls7-8 {
  fill: #b96228;
}

.cls7-29 {
  fill: #231f20;
  font-family: Avenir-Light, Avenir;
  font-size: 7px;
  font-weight: 300;
}