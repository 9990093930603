.homeContent {
  /* width: 100%;
  height: 100%; */
  display: flex;
  flex: 1;
  flex-direction: column;
  /* justify-content: center; */
  position: relative;
}

.homeMessageOverlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(11, 37, 65, 0.5);
  height: 100%;
  width: 100%;
  z-index: 1;
}

.homeMessageContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  /* background: linear-gradient(to bottom, rgba(112, 128, 144, 1) 50%, rgba(112, 128, 144, 0.8) 100%); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  position: relative;
  color: white;
  min-height: calc(100vh - 100px);
}

.homeMessage {
  font-size: 2.5rem;
  line-height: 3rem;
  font-weight: 300;
  text-align: left;
  z-index: 1;
  color: floralwhite;
}

.homeMessageText {
  margin-left: 80px;
  margin-right: 80px;
  max-width: 1200px;
  text-align: center;
}

.homeMessageTextReegineering {
  font-size: 3rem;
  font-weight: 700;
}

.videoBg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 0;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* TECHNOLOGY AND PIPELINE */

.homeTechSection {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 80px;
  padding-top: 160px;
  padding-bottom: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.homeTechPipeButtonSection {
  flex-direction: row;
}

.homeTechPipeHeadline {
  font-size: 2.2rem;
  font-weight: 600;
  max-width: 1000px;
  margin-bottom: 80px;
}


.homeTechPipeButtons {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.homeTechPipeButton {
  padding: 20px;
  border-radius: 0.5rem;
  background-color: rgba(206, 87, 47, 1);
  cursor: pointer;
  color: white;
  font-weight: 500;
  font-size: 1.2rem;
  height: 400px;
  width: 450px;
}

.homeTechPipeButton > a {
  color: inherit;
  text-decoration: none;
  background-color: transparent;
  cursor: inherit;
  border: none; /* In case borders are defined */
  outline: none; /* Removes focus outline, though it might affect accessibility */
  padding: 0; /* In case padding is added */
  margin: 0; /* In case margins are added */
  text-align: left;
}

.homeTechPipeButton:hover {
  background-color: rgba(11, 37, 65, 1);
}

.homeTechPipeButtonContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.homeTechPipeButtonText {
  margin: 40px;
  margin-left: 0px;
  font-size: 1.8rem;
  line-height: 2rem;
}

.homeTechPipeButtonLink {
  font-size: 1.5rem;
}


/* LATEST NEWS */

.latestNews {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 0px;
  background: linear-gradient(to bottom, rgba(112, 128, 144, 0.8) 0%, rgba(255, 255, 255, 0.8) 50%);
  padding-top: 160px;
  padding-bottom: 200px;
  /* min-height: calc(100vh - 100px); */
}

.latestNewsSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  max-width: 80%;
}

.latestNewsSectionTitle {
  background-color: rgba(11, 37, 65, 1);
  color: floralwhite;
  font-size: 2rem;
  font-weight: 600;
  align-self: flex-start;
  border-radius: 2rem;
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
  transition: width 0.5s ease;
}

.latestNewsList {
  box-sizing: border-box;
  transition: width 0.5s ease;
}

.latestNewsListUl {
  padding: 0px;
}

.latestNewsListUl > li {
  text-decoration: none;
  list-style-type: none;
  text-align: left;
  margin-top: 20px;
}

.latestNewsItem {
  display: flex;
  flex-direction: row;
  /* margin-bottom: 30px; */
}

.latestNewsDate {
  font-size: 0.8rem;
  font-weight: 600;
  padding-bottom: 2px;
}

.latestNewsInfo {
  display: flex;
  flex-direction: column;
  border-top-style: dashed;
  border-top-width: 1px;
  border-top-color: black;
  padding-top: 2px;
}

.latestNewsTitle {
  font-size: 1.1rem;
  line-height: 1.4rem;
  font-weight: 500;
  color: rgba(1,53,99,1);
}

.latestNewsTitle > a {
  text-decoration: none;
  color: rgba(11, 37, 65, 1);
}

.latestNewsTitle:hover > a {
  color: slategrey;
}

.latestNewsDescription {
  font-size: 1rem;
  line-height: 1.2rem;
}

.latestNewsIcon {
  max-width: 40px;
  min-width: 40px;
  margin-right: 30px;
  fill: black;
}

@media screen and (max-width: 1024px) {
  .micellimg {
    max-width: 90%;
  }

  .homeTechPipeButtons {
    flex-direction: column;
    gap: 40px;
  }

  .homeTechPipeButton {
    width: auto;
    max-width: 400px;
    margin-left: 40px;
    margin-right: 40px;
  }

}

@media screen and (max-width: 640px) {
  .homeMessage {
    font-size: 1.8rem;
    line-height: 1.8rem;
    text-align: left;
  }

  .homeMessageText {
    max-width: 400px;
    margin-right: 50px;
    margin-left: 50px;
  }

  .homeMessageTextReegineering {
    font-size: 2rem;
    font-weight: 600;
  }

  .homeTechPipeButton {
    height: auto;
  }

  .homeTechPipeButtonText {
    font-size: 1.4rem;
  }

  .homeTechPipeButtonLink {
    font-size: 1.2rem;
  }

}