.navContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  min-height: 100px;
  background-color: white;
}

.navBarLogo {
  margin-left: 40px;
  min-height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navBarLogo:last-of-type {
  margin-left: 0px;
}

.navBarLogo > img {
  width: 130px;
}

.horizontalNavBar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: inherit;
}

.horizontalNavBar > ul {
  display: flex;
  flex-direction: row;
}

.horizontalNavBar > ul > li {
  list-style-type: none;
  margin-right: 1.8rem;
  border-radius: 0.75rem;
}

.horizontalNavBar > ul > li > a {
  text-decoration: none;
  text-transform: uppercase;
  color: rgba(11, 37, 65, 1);
  font-size: 0.9rem;
  font-weight: 400;
  width: 2.5rem;
  background-color: white;
  border-radius: 0.75rem;
  height: 0.5rem;
  padding: 0.5rem;
}

.menuOpenSvg {
  height: inherit;
  align-self: center;
  position: absolute;
  right: 20px;
  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  fill: #192751;
}

.menuOpenSvg:hover {
  fill: #009ade;
}

.dropdownNavBar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 100vh;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  background-color: rgba(11, 37, 65, 1);
}

.menuCloseSvg {
  height: 100px;
  fill: rgba(206, 87, 47, 1);
  cursor: pointer;
  align-self: flex-end;
  margin-right: 20px;
}

.dropdownMenuContent {
  display: flex;
  flex-direction: column;
  padding: 0px;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
  padding-right: 10px;
  padding-left: 10px;
}

.dropdownMenuContent > ul {
  list-style-type: none;
}

.dropdownMenuContent > ul > li > a {
  text-decoration: none;
  text-transform: uppercase;
  padding-right: 20px;
  padding-left: 20px;
  color: white;
  font-size: 1.6rem;
  font-weight: 500;
}

.dropdownMenuContent > ul > li > a:hover {
  color: rgba(206, 87, 47, 1);
}

@media all and (max-width: 1024px) {
  .horizontalNavBar {
    display: none;
  }

  .menuOpenSvg {
    display: block;
  }

}

@media all and (min-width: 1024px) {
  .horizontalNavBar {
    display: flex;
  }

  .menuOpenSvg {
    display: none;
  }
}

@media all and (max-width: 320px) {
  .navBarLogo {
    display: none;
  }
}