.leaflet-container {
  height: 400px;
  width: 600px;
  -webkit-filter: grayscale(50%);
  filter: grayscale(50%);
}


@media screen and (max-width: 1200px) {
  .leaflet-container {
    width: 400px;
  }
}

@media screen and (max-width: 600px) {

  .leaflet-container {
    height: 300px;
    width: 300px;
  }

}