.technologyCcontent {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  align-items: center;
}

.technologySectionAllo {
  /* background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.95) 35%); */
  background-color: rgba(11, 37, 65, 0.9);
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 80px;
}

.technologySectionMultiplex {
  /* background: linear-gradient(to right, rgba(112, 128, 144, 0.9) 0%, rgba(112, 128, 144, 0.8) 45%, rgba(255, 255, 255, 0.95) 85%, rgba(255, 255, 255, 1) 100%); */
  /* background: linear-gradient(to right, rgba(1,53,99, 0.8) 0%, rgba(1,53,99, 0.8) 53%, rgba(255, 255, 255, 0.95) 53%, rgba(255, 255, 255, 1) 100%); */
  /* background: linear-gradient(to bottom, rgba(11, 37, 65, 0.6) 0%, rgba(11, 37, 65, 0.3) 40%, rgba(255, 255, 255, 0.95) 80%, rgba(255, 255, 255, 1) 100%); */
  /* background-color: rgba(11, 37, 65, 0.9); */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.95) 35%);
  width: 100%;
  box-sizing: border-box;
  padding-top: 80px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section {
  width: 80%;
}

.technologySectionTitle {
  font-size: 2rem;
  font-weight: 600;
  background-color: rgba(206, 87, 47, 1);
  color: white;
  border-radius: 2.5rem;
  padding: 20px;
  max-width: 1000px;
  width: 100%;
  margin-bottom: 20px;
}

.technologySectionTitleRevColor {
  background-color: rgba(206, 87, 47, 1);
  color: white;
}

/* Promise of Allogeneic */

.alloSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
}

.promiseOfAlloText {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  justify-content: center;
}

.promiseOfAlloIntro {
  font-size: 1.2rem;
  line-height: 2rem;
  color: white;
  font-weight: 400;
  text-align: justify;
  max-width: 1000px;
  padding-top: 0px;
  padding-bottom: 40px;
  justify-self: center;
  align-self: center;
}

.promiseOfAlloImg {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.autoVsAlloDiv {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  /* text-align: center; */
  transition: max-width 0.5s ease;
}

.autoVsAlloDiv:first-of-type {
  margin-right: 160px;
}

.autoVsAlloTitle, .autoVsAlloContent {
  max-width: 500px;
}

.autoVsAlloTitle {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: rgba(11, 37, 65, 1);
}

.autoVsAlloContent {
  font-size: 1.1rem;
  margin-bottom: 40px;
  font-style: italic;
}

.autoVsAlloImg {
  /* max-width: 600px; */
  max-height: 340px;
  transition: max-width 0.5s ease;
}

/* Multiplex */

.multiplexSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1000px;
}

.multiplexTextContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.micellimg {
  max-width: 300px;
}

.multiplexText {
  max-width: 1000px;
  text-align: justify;
  font-size: 1.1rem;
  line-height: 1.6rem;
  color: black;
  margin-top: 40px;
  margin-bottom: 40px;
}

/* THE FUTURE */

.theFuture {
  background-color: rgba(255,255,255,0.9);
  color: rgba(11, 37, 65, 1);
  padding: 124px;
  padding-top: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 80px;
  border-radius: 2rem;
  max-width: 1000px;
  /* min-height: calc(100vh - 100px); */
}

.theFutureContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
    align-items: center;
    gap: 80px;
}

.theFutureFirstLine {
  display: flex;
  flex-direction: column;
  justify-self: flex-start;
  align-self: flex-start;
  max-width: 400px;
}

.theFutureDescription {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.theFutureDescriptionHeadline {
  font-size: 1.8rem;
  font-weight: 500;
  text-align: left;
  line-height: 2.2rem;
  text-align: center;
}

.micellimg {
  max-width: 360px;
  margin-top: 64px;
  margin-bottom: 64px;
}

.theFutureDescriptionLowerPoints {
  font-size: 1.2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.theFutureDescriptionLowerPointHeadline {
  font-size: 1.4rem;
  font-weight: 600;
}

.theFutureSecondLine {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: center;
  gap: 30px;
  max-width: 400px;
}

.theFuturePoint {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.futureIcon  {
  height: 48px;
  width: 48px;
  /* background-color: rgba(206, 87, 47, 1); */
  background-color: rgba(11, 37, 65, 1);
  border-radius: 50%;
  padding: 10px;
  align-self: flex-start;
}

.theFuturePointText {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.theFuturePointHeader {
  margin-left: 20px;
  font-size: 1.4rem;
  font-weight: 600;
  text-align: left;
  color: rgba(206, 87, 47, 1);
}

.theFuturePointBullets {
  margin-left: 20px;
  text-align: start;
  font-size: 1rem;
  line-height: 1.6rem;
  padding-right: 20px;
}

/* PUBLICATIONS */

.technologySectionPublications {
  background-color: rgba(255, 255, 255, 0.9);
  padding-top: 80px;
  padding-bottom: 80px;
  width: 100%;
  display: flex;
  justify-content: center;;
}

.publicationsSection {
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.publicationsContent {
  max-width: 1000px;
}

.publicationsList > li {
  text-decoration: none;
  list-style-type: none;
  text-align: left;
}

.publicationItem {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}

.publicationDate {
  /* width: 90px; */
  font-size: 1.1rem;
  font-weight: 600;
  padding-bottom: 2px;
}

.publicationInfo {
  display: flex;
  flex-direction: column;
  border-top-style: dashed;
  border-top-width: 1px;
  border-top-color: black;
  padding-top: 2px;
}

.publicationTitle > a {
  text-decoration: none;
  font-size: 1.2rem;
  line-height: 1.4rem;
  font-weight: 500;
  cursor: pointer;
  color: rgba(11, 37, 65, 1);
}

.publicationTitle:hover > a {
  color: slategrey;
}

.publicationAuthors {
  font-size: 1rem;
  line-height: 1.2rem;
}

.publicationIcon {
  max-width: 40px;
  min-width: 40px;
  margin-right: 30px;
  fill: black;
}

.publicationsList {
  padding-inline-start: 0px;
}

/* Media size */
@media screen and (max-width: 1800px) {
  .autoVsAlloDiv, .autoVsAlloImg {
    max-width: 450px;
  }

}

@media screen and (max-width: 1714px) {
  .publicationsSection {
    flex-direction: column;
  }

  .publicationsContent {
    margin-left: 0px;
  }

  .technologyTitle {
    align-self: center;
  }
}

@media screen and (max-width: 1500px) {
  .autoVsAlloDiv, .autoVsAlloImg {
    max-width: 400px;
  }

  .autoVsAlloTitle {
    font-size: 1.4rem;
  }

  .autoVsAlloContent {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1200px) {
  .autoVsAlloDiv, .autoVsAlloImg {
    max-width: 350px;
  }

  .promiseOfAlloText {
    flex-direction: column;
  }

  .promiseOfAlloIntro {
    padding-top: 0px;
    align-self: center;
  }
}

@media screen and (max-width: 1100px) {
  .multiplexSection {
    flex-direction: column;
  }

  .multiplexTextContent {
    justify-content: center;
    align-items: center;
    margin-right: 0px;
  }

  .technologySectionTitleRevColor {
    align-self: center;
  }

  .technologySectionMultiplex {
    /* background: linear-gradient(to bottom, rgba(1, 53, 99, 0.8) 0%, rgba(1, 53, 99, 0.8) 40%, rgba(255, 255, 255, 0.95) 60%, rgba(255, 255, 255, 1) 100%); */
    display: flex;
    justify-content: center;
    align-items: center;

  }

}

@media screen and (max-width: 1000px) {

  .autoVsAlloDiv, .autoVsAlloImg {
    max-width: 400px;
  }

  .promiseOfAlloImg {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .autoVsAlloDiv:first-of-type {
    margin-right: 0px;
    margin-bottom: 100px;
  }

  .multiplexTextContent {
    flex-direction: column;
    align-items: center;
  }

  .theFuture {
    padding: 40px;
    padding-top: 80px;
    flex-direction: column;
    align-items: center;
    justify-items: center;
  }

  .theFutureContent {
    flex-direction: column;
  }

  .theFutureFirstLine {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    max-width: 600px;
  }

  .theFutureSecondLine {
    max-width: 600px;
  }

  .theFutureDescriptionHeadline {
    align-self: center;
    font-size: 1.4rem;
  }

  .theFutureDescriptionLowerPointHeadline {
    font-size: 1.2rem;
  }

  .theFutureDescription {
    margin-right: 0px;
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media screen and (max-width: 800px) {
  .autoVsAlloDiv, .autoVsAlloImg {
    max-width: 375px;
  }
}

@media screen and (max-width: 600px) {

  .autoVsAlloDiv,
  .autoVsAlloImg {
    max-width: 350px;
  }

  .theFuturePoint {
    flex-direction: column;
  }

  .futureIcon {
    justify-content: center;
    align-items: center;
    align-self: center;
  }

}

@media screen and (max-width: 500px) {

  .autoVsAlloDiv,
  .autoVsAlloImg {
    max-width: 300px;
  }

  .promiseOfAlloIntro {
    text-align: left;
  }

  .multiplexText {
    text-align: left;
  }
}