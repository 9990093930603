.newsContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 0px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.8) 50%);
  padding-top: 160px;
  padding-bottom: 200px;
}

.newsSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  max-width: 80%;
}

.newsSectionTitle {
  background-color: rgba(206, 87, 47, 1);
  color: white;
  font-size: 2rem;
  font-weight: 600;
  align-self: flex-start;
  transition: width 0.5s ease;
  border-radius: 2rem;
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
}

.newsList {
  box-sizing: border-box;
  transition: width 0.5s ease;
}

.newsListUl {
  padding: 0px;
}

.newsListUl > li {
  text-decoration: none;
  list-style-type: none;
  text-align: left;
  margin-top: 20px;
}

.newsItem {
  display: flex;
  flex-direction: row;
  /* margin-bottom: 30px; */
}

.newsDate {
  font-size: 0.8rem;
  font-weight: 600;
  padding-bottom: 2px;
}

.newsInfo {
  display: flex;
  flex-direction: column;
  border-top-style: dashed;
  border-top-width: 1px;
  border-top-color: black;
  padding-top: 2px;
}

.newsTitle {
  font-size: 1.1rem;
  line-height: 1.4rem;
  font-weight: 500;
}

.newsTitle > a {
  text-decoration: none;
  color: rgba(11, 37, 65, 1)
}

.newsTitle:hover > a {
  color: slategrey;
}

.newsDescription {
  font-size: 1rem;
  line-height: 1.2rem;
}

.newsIcon {
  max-width: 40px;
  min-width: 40px;
  margin-right: 30px;
  fill: black;
}
