.partneringContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: rgba(11, 37, 65, 0.9);
  padding-top: 80px;
  padding-bottom: 80px;
  align-items: center;
  justify-content: center;
  margin: 0px;
  flex: 1;
}

.partneringTitle {
  font-size: 2rem;
  font-weight: 600;
  background-color: rgba(206, 87, 47, 1);
  color: white;
  margin-bottom: 60px;
  margin-left: 80px;
  margin-right: 80px;
  border-radius: 2rem;
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
}

.partneringText {
  max-width: 800px;
  text-align: justify;
  font-size: 1.2rem;
  line-height: 2.2rem;
  padding-left: 80px;
  padding-right: 80px;
  color: white;
}

.partneringContactButton {
  margin-top: 40px;
  font-size: 1.3rem;
  cursor: pointer;
}

.partneringContactButton > a {
  text-decoration: none;
  color: white;
}

.partneringContactButton > a:hover {
  color: lavender;
}

.currentPartnersSection {
  /* margin-top: 40px; */
  padding-top: 80px;
  padding-bottom: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 40px;
  justify-content: center;
  align-items: center;
}

.currentPartnerBox {
  height: 300px;
  width: 300px;
  border-radius: 2rem;
  padding: 40px;
  /* background-color: rgba(206, 87, 47, 1); */
  background-color: rgba(255, 255, 255, 0.9);
  text-align: left;
  color: white;
  color: black;
}

.currentPartnerName {
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 20px;
}

.currentPartnerLogo {
  width: auto;
  height: auto;
  max-width: 250px;
}

.currentPartnerDescription {
  margin-top: 20px;
  font-size: 1rem;
  line-height: 1.4rem;
}

@media screen and (max-width: 1024px) {
  .currentPartnersSection {
    flex-direction: column;
  }

  .currentPartnerBox {
    max-width: 300px;
    width: unset;
    height: auto;
    margin-left: 40px;
    margin-right: 40px;
  }

  .currentPartnerLogo {
    width: 80%;
  }
}

@media screen and (max-width: 600px) {
  .partneringText {
    font-size: 1rem;
    line-height: 1.4rem;
    padding-left: 40px;
    padding-right: 40px;
  }
}