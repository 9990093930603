.pipelineCcontent {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  align-items: center;
  /* background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.95) 35%); */
  background: rgba(255, 255, 255, 0.9);
}

.pipelineTableTitle {
  font-size: 2rem;
  font-weight: 600;
  background-color: rgba(206, 87, 47, 1);
  color: white;
  margin: 40px;
  margin-top: 80px;
  margin-bottom: 40px;
  border-radius: 2rem;
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
}

.pipelineSection {
  max-width: 1100px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
}

.section {
  width: 100%;
  display: flex;
  justify-content: center;
}

.pipelineHorizontal {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.pipelineVertical {
  display: none;
  flex-direction: column;
  width: 100%;
}

.horizontalHeader {
  font-weight: bold;
  background-color: white!important;
  cursor: unset!important;
  color: unset!important;
}

.horizontalRow {
  display: flex;
  background-color: rgba(214, 222, 228, 1);
}

.horizontalRowOdd {
  background-color: rgba(237, 241, 244, 1);
}

.horizontalCell {
  flex: 1;
  padding: 12px;
  text-align: left;
  /* margin-bottom: 4px; */
}

.horizontalCell.product { min-width: 100px; max-width: 100px; }
.horizontalCell.indication { min-width: 260px; max-width: 260px; }
.horizontalCell.desc { min-width: 480px; max-width: 480px; }
.horizontalCell.car { min-width: 120px; max-width: 120px; }
.horizontalCell.open {
  /* max-width: 36px;
  min-width: 36px; */
  padding: 0px;
  /* margin-left: 12px; */
  align-self: center;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 48px;
  display: flex;
}

.horizontalRow:hover {
  background-color: rgba(11, 37, 65, 1);
  cursor: pointer;
  color: white;
}


.expandable {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* flex-direction: row;
  justify-content: flex-start;
  align-items: center; */
  padding-top: 30px;
  margin-bottom: 30px;
  background-color: white;
}

.expandableDescription {
  margin-top: 30px;
  margin-bottom: 30px;
}

.expandableSlide {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.expandableImage {
  flex: 1;
  align-self: center;
  justify-self: flex-end;
  padding: 40px;
}

.expandableImage > * {
  max-width: 400px;
}

.expandableExplanation {
  flex: 1;
}

.expandableExplanationAddition {
  padding: 16px;
  border-radius: 1rem;
  color: white;
  font-size: 1.1rem;
  background-color: rgba(206, 87, 47, 1);
  text-align: left;
}

.expandableExplanationSilencing {
  margin-top: 24px;
  padding: 16px;
  border-radius: 1rem;
  color: white;
  font-size: 1.2rem;
  background-color: rgba(11, 37, 65, 1);
  text-align: left;
}

.expandableExplanationText {
  text-align: left;
  width: 100%;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  color: black;
}

.expandableExplanationTextHighlight {
  font-weight: 600;
}

.expandableExplanationAddition + .expandableExplanationText > div > span.expandableExplanationTextHighlight {
  /* color: rgba(206, 87, 47, 1); */
  /* color: rgba(11, 37, 65, 1); */
  color: black;
}

.expandableExplanationSilencing + .expandableExplanationText > div > span.expandableExplanationTextHighlight {
  /* color: rgba(11, 37, 65, 1); */
  color: black;
}

.expandableExplanationTextDescriptionP {
  margin-block-start: 0.2rem;
  margin-block-end: 0.2rem;
}

.verticalRow {
  display: flex;
  flex-direction: column;
}

.verticalHeader {
  font-weight: bold;
  align-self: flex-end;
}

.verticalCell {
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgba(214, 222, 228, 1);
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
}

.verticalCellTitle {
  font-weight: 600;
  color: rgba(206, 87, 47, 1);
  text-align: left;
  justify-self: flex-start;
  /* background-color: white; */
  /* padding: 8px; */
  width: 100px;
}

.verticalCellContent { 
  color: rgba(11, 37, 65, 1);
  justify-self: flex-start;
  text-align: left;
}

.verticalCell.open {
  cursor: pointer;
  height: 100%;
  justify-content: center;
  min-width: 24px;
  background-color: transparent;
}

/* Media query for screens less than or equal to 600px wide */
@media (max-width: 1200px) {
  .pipelineHorizontal {
    display: none;
  }

  .pipelineVertical {
    display: flex;
  }

  .expandable {
    background-color: white;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
  }
  
  .expandableSlide {
    flex-direction: column;
    width: auto;
    max-width: 800px;
    padding: 40px;
  }

  .expandableImage {
    align-self: unset;
    padding: 0px;
    margin-bottom: 40px;
  }
}

@media (max-width: 460px) {
  .verticalCell {
    flex-direction: column;
    gap: 4px;
    text-align: left;
  }

}